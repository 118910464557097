const fetchLayout = (themeName, layout) =>
  import(
    /* webpackChunkName: "layoutOverrides" */ `../theme-configs/${themeName}/layoutOverrides`
  )
    .catch(() =>
      import(
        /* webpackChunkName: "layoutConfigs" */ `../layout-configs/${layout}`
      )
    )
    .then((m) => m.default);

export default fetchLayout;
