import './loadPolyfills';
import { fetchSettings } from './utils/authentication';
import fetchTheme from './api/fetchTheme';
import fetchLayout from './api/fetchLayout';

let authRequestPending = false;
let widgetsCount = 0;
let abortController;

const validateOptions = (opts) => {
  const errorMessage = 'Initialization failed.';
  if (!(opts.target instanceof HTMLElement)) {
    throw new Error(
      `${errorMessage} Please provide correct DOM node for option \`target\`.`
    );
  }
  if (!opts.brand_id) {
    throw new Error(`${errorMessage} Please provide option \`brand_id\`.`);
  }
};

const warnMaxBetSlipHeight = (opts) => {
  if (opts.maxBetSlipHeight) {
    console.warn(
      'Option maxBetSlipHeight is deprecated. ' +
        'Use betSlipOffsetTop instead. You can update ' +
        'betslip height restrictions in runtime calling ' +
        'updateOptions({ betSlipOffsetTop: 5, betSlipOffsetBottom: 10 })'
    );
    opts.betSlipOffsetTop =
      window.innerHeight -
      Math.min(
        window.innerHeight - opts.betSlipOffsetTop,
        opts.maxBetSlipHeight
      );
  }
};

const warnBetslipOffsetMethods = () =>
  console.warn(
    'Methods betSlipOffsetTop and betSlipOffsetBottom are deprecated. ' +
      'Use updateOptions({ betSlipOffsetTop: 5, betSlipOffsetBottom: 10 }) instead.'
  );

const preloadFetch = (url) => {
  const preloadLink = document.createElement('link');
  preloadLink.href = url;
  preloadLink.rel = 'preload';
  preloadLink.as = 'fetch';
  preloadLink.crossOrigin = 'anonymous';
  document.head.appendChild(preloadLink);
};

const preloadWebline = (type, opts) => {
  const getUrl = (v) =>
    `${opts.apiUrl}/api/v3/${type}/brand/${opts.brand_id}/${opts.lang}/${v}`;
  fetch(getUrl(0))
    .then((data) => data.json())
    .then((data) => {
      data?.fixtures_versions?.map((v) => preloadFetch(getUrl(v)));
      data?.markets_versions?.map((v) => preloadFetch(getUrl(v)));
    });
};

const preloadData = (opts) => {
  preloadFetch(
    `${opts.apiUrl}/api/v1/descriptions/markets/${opts.lang}/${opts.brand_id}`
  );
  preloadFetch(`${opts.apiUrl}/api/v1/descriptions/statuses/${opts.lang}`);
  preloadWebline('live', opts);
  preloadWebline('prematch', opts);
};

const fetchThemeAndLayout = (themeName) =>
  fetchTheme(themeName).then((theme) =>
    fetchLayout(themeName, theme.layoutType)
  );

const formatOptions = (opts) => ({
  ...opts,
  apiUrl:
    (window.REACT_APP_DEBUG_MODE === 'true' ||
      process.env.REACT_APP_DEBUG_MODE === 'true') &&
    opts.apiUrl
      ? opts.apiUrl
      : window.REACT_APP_API_URL || process.env.REACT_APP_API_URL || '',
});

function BTRenderer() {
  const appPromise = import(
    /* webpackChunkName: "App", webpackPreload: true */ './App'
  ).then((v) => {
    window.btLoadingAppChunkTime = Date.now() - window.btInitializeApp;
    return new v.default();
  });

  const initialize = (opts) => {
    window.btInitializeApp = Date.now();
    const options = formatOptions(opts);
    validateOptions(options);
    warnMaxBetSlipHeight(options);

    if (options.target.__btRoot) {
      console.log('💫 BT: rerender triggered');
      options.target.__btRoot.kill();
    }

    if (!widgetsCount) {
      fetchThemeAndLayout(options.themeName);
    }

    if (!widgetsCount && !options.widgetName) {
      preloadData(options);
    }

    appPromise.then((app) => app.initialize(options));

    if (!widgetsCount && !authRequestPending) {
      authRequestPending = true;

      abortController = new AbortController();

      Promise.all([
        appPromise,
        fetchSettings(options, {
          signal: abortController.signal,
          onFailedAttempt: (error) =>
            appPromise.then((app) => app.onSettingsError(error)),
        }),
      ])
        .then(([app, res]) => app.onSettingsReceived(res))
        .finally(() => {
          authRequestPending = false;
        });
    }
    widgetsCount = widgetsCount + 1;

    return this;
  };

  const kill = () => {
    console.log('💣 BT: kill triggered');
    authRequestPending = false;
    abortController.abort();
    widgetsCount = widgetsCount - 1;
    appPromise.then((app) => app.kill());
  };
  const updateOptions = (opts) =>
    appPromise.then((app) => app.updateOptions(opts));
  const action = (...args) => appPromise.then((app) => app.action(...args));

  // Public methods
  this.initialize = initialize;
  this.kill = kill;
  this.updateOptions = updateOptions;
  this.action = action;

  // Legacy public methods
  this.command = (command, url) => {
    console.warn(
      'method command is deprecated, if you used command("go_to", "url"), please use updateOptions({ url: "url" }) instead'
    );
    updateOptions({ url });
  };
  this.goToPage = (url) => {
    console.warn(
      'method goToPage("url") is deprecated, please use updateOptions({ url: "url" }) instead'
    );
    updateOptions({ url });
  };
  this.betSlipOffsetTop = (betSlipOffsetTop) => {
    warnBetslipOffsetMethods();
    updateOptions({ betSlipOffsetTop });
  };
  this.betSlipOffsetBottom = (betSlipOffsetBottom) => {
    warnBetslipOffsetMethods();
    updateOptions({ betSlipOffsetBottom });
  };
}

console.log(
  `🏈 BTRenderer build time: ${new Date(
    process.env.REACT_APP_BUILD_TIME * 1000
  ).toLocaleString()}`
);
window.BTRenderer = window.BTRenderer || BTRenderer;
